<template>
  <span v-html="value"></span>
</template>

<script>
  export default {
    name: 'tdColSafeHTML',
    props: ['value']
  }
</script>
